import { Injectable, Injector, Inject }    from '@angular/core';
import { Overlay, OverlayRef }             from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { ToastComponent }                             from './toast.component';
import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig } from './toast-manager.config';
import { ToastRef }                                   from './toast-ref.model';
import { TranslateService }                           from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CsToastManagerService {
  private lastToast: ToastRef;

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) {
  }

  show(data: ToastData) {
    this.checkForTitle(data);
    const positionStrategy = this.getPositionStrategy();
    const overlayRef       = this.overlay.create({positionStrategy});

    const toastRef    = new ToastRef(overlayRef);
    this.lastToast    = toastRef;
    const injector    = this.getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);

    overlayRef.attach(toastPortal);

    return toastRef;
  }

  private getPositionStrategy() {
    return this.overlay.position()
               .global()
      //.top(this.getPosition())
               .bottom(this.getPosition())
               .right(this.toastConfig.position.right + 'px');
  }

  private getPosition() {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    if (this.lastToast) {
      console.log(this.lastToast);
      console.log(this.lastToast.isVisible());
    }
    const position = lastToastIsVisible
      ? ((this.lastToast.getPosition().top - this.lastToast.getPosition().height) > 0 ?
        (window.innerHeight - this.lastToast.getPosition().top) : this.toastConfig.position.bottom)
      : this.toastConfig.position.bottom;

    return position + 'px';
  }

  private getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    const tokens = new WeakMap();

    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }

  /**
   * When there is no title use the uppercase variant of the the type to find the translated string
   */
  private checkForTitle(data: ToastData) {
    if (data.title)
      return;

    const i8n  = this.parentInjector.get(TranslateService);
    data.title = i8n.instant(`VERB_${data.type.toUpperCase()}`);
  }
}
