import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsLoggedInGuard }      from '@cs/performance-manager';

import { Environment }     from '@cs/common';
import { defaulAppRoutes } from '../src/app/app-routing.module';
import { pmAppSettings }   from '../src/environments/environment';

const routes: Routes = [
	{
		path:     '',
		children: [
			{
				path:         'login',
				canActivate:  [IsLoggedInGuard],
				loadChildren: () => import('./module-wrappers/customer-login-module-loader').then(m => m.CustomerLoginLoaderModule)
			},
			// importing the default routing
			...defaulAppRoutes[0].children
		]
	}
];


@NgModule({
						imports: [RouterModule.forRoot(routes, {enableTracing: pmAppSettings.environment !== Environment.PRODUCTION})],
						exports: [RouterModule]
					})
export class CustomerRoutingModule {
}
