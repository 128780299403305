<div class="login-form-container">
    <form enctype="application/x-www-form-urlencoded"
          (keyup.enter)="useGoogleCapthca ? false : login()">
        <div class="form-group">
            <i class="input-icon mdi mdi-account"></i>
            <input class="form-control"
                   type="text"
                   name="username"
                   placeholder="{{'USERNAME' | translate | capitalize}}"
                   (keydown)="resetErrorState()"
                   [(ngModel)]="loginModel.username"
                   required="">
        </div>
        <div class="form-group" *ngIf="loginModel?.requiresPassword">
            <i class="input-icon mdi mdi-key-variant"></i>
            <input class="form-control"
                   type="password"
                   name="password"
                   placeholder="{{'PASSWORD' | translate | capitalize}}"
                   (keydown)="resetErrorState()"
                   [(ngModel)]="loginModel.password"
                   required="">
        </div>
        <div class="form-group" *ngIf="useGoogleCapthca">
            <div class="mb-1 g-recaptcha-container" [innerHtml]="captchaHtml">
            </div>
        </div>
        <div [matTooltip]="tooltipText" (mouseenter)="setTooltipText()">
            <!-- changing the buttons for the new google invisible captcha V2 -->
            <ng-container *ngIf="useGoogleCapthca">
				<button id="form-submit"
                    class="btn btn--primary btn--lg"
                    [csLoader]="$inProgress | async"
                    [loaderAdditionalClasses]="['loader--white']"
                    [disabled]="loginModel?.username?.length < 1 || (loginModel?.requiresPassword && loginModel?.password?.length < 1) ">
                {{'LOGIN' | translate | capitalize}}
                </button>
			</ng-container>
            <ng-container *ngIf="!useGoogleCapthca">
                <button
                    class="btn btn--primary btn--lg"
                    [csLoader]="$inProgress | async"
                    [loaderAdditionalClasses]="['loader--white']"
                    [disabled]="loginModel?.username?.length < 1 || (loginModel?.requiresPassword && loginModel?.password?.length < 1) "
                    (click)="login()">
                {{'LOGIN' | translate | capitalize}}
            </button>
            </ng-container>
        </div>
        <div *ngIf="filterActive(loginOptions).length > 0">
            <div class="mb-3 mt-3 f-body-s f-color-dimmed">{{ "OR" | translate}}</div>
            <ng-container *ngFor="let option of filterActive(loginOptions)">
                <div [@inOutAnimation]>
                    <button class="btn btn--icon-and-text btn--lg auth-provider-button text-truncate text-no-whitespace"
                            [disabled]="($inProgress | async)"
                            (click)="switchMethod(option)">
                        <i class="{{getAuthIcon(option.key)}}"></i>
                        <div class="full-width">{{ "CONTINUE_WITH" | translate:{method: option.label} }}</div>
                    </button>
                </div>
            </ng-container>
        </div>
    </form>
    <p class="card-text login-footer-text">
        {{'IF_YOU_HAVE_PROBLEMS_LOGGING_IN' | translate | capitalize}}
        <a href="mailto:{{loginConfig.supportEmail}}"
           class="link">{{loginConfig.supportEmail}}</a>
    </p>
    <div *ngIf="showResetPassword"
         class="login-form--bottom-bar">
        <a [routerLink]="loginConfig.RECOVER_URL"
           class="link font-weight-bold">
            {{'FORGOT_PASSWORD' | translate | capitalize}}
        </a>
    </div>
</div>
