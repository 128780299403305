import {
	Input,
	Output,
	EventEmitter,
	OnChanges,
	ContentChild,
	TemplateRef,
	ViewChild,
	forwardRef,
	ChangeDetectionStrategy,
	ElementRef,
	ChangeDetectorRef, SimpleChanges, HostBinding
}                                                  from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SafeMethods }                             from '@cs/common/utils';
import { isNumber }                                from '@cs/components/util';
import { KeyValuePair }                            from '@cs/core/generate';
import { any }                                     from 'codelyzer/util/function';
import { debounceTime }                            from 'rxjs/operators';

import {
	SelectionChangedEventArgs,
	DropdownOpenStateEventArgs, SelectionChangedWithSearchEventArgs, FilterSelectionChangedEventArgs
}                                                      from './event-args';
import { AdvancedDropdownGroup, AdvancedDropdownItem } from './models';

import { isNullOrUndefined, isString } from '@cs/core';
import { Component }                   from '@angular/core';
import { isArray }                     from '@datorama/akita';
import { LoggerUtil }                  from '@cs/core';
import { CdkVirtualScrollViewport }    from '@angular/cdk/scrolling';

@Component({
												selector:        'cs-advanced-dropdown',
												templateUrl:     './advanced-dropdown.component.html',
												changeDetection: ChangeDetectionStrategy.OnPush,
												providers:       [
													{
														provide:     NG_VALUE_ACCESSOR,
														useExisting: forwardRef(() => CsAdvancedDropdownComponent),
														multi:       true
													}
												]
											})
export class CsAdvancedDropdownComponent implements OnChanges, ControlValueAccessor {
	currentQuery: string;

	/**
		* Gets the index of the focussedElement (highlighted)
		*/
	private get focussedElement(): number {
		return this._focussedElement;
	}

	/**
		* Sets the index of the focussedElement (highlighted)
		* For NO hightlighting set to -1
		*/
	private set focussedElement(value: number) {
		this._focussedElement = value;

		const flattenedlist = this.getFlattendItemList();
		// unhighlite all
		flattenedlist.forEach(x => x.highlighted = false);

		if (this.focussedElement > -1) {
			flattenedlist[this.focussedElement].highlighted = true;
			console.log('higlighted element : ' + flattenedlist[this.focussedElement].label);
			setTimeout(() => {
				this.moveHighlightedIntoView(this.focussedElement);
			});

		} else {
			console.log('highlighted element : none');
		}
	}

	originalGroups: Array<AdvancedDropdownGroup> = new Array<AdvancedDropdownGroup>();
	groups: Array<AdvancedDropdownGroup>         = new Array<AdvancedDropdownGroup>();
	query: string;
	selectedItem: Array<AdvancedDropdownItem>    = [];

	isTruncated = false;

	/**
		* indicates if the menu should be closed after selection
		*/
	@Input() closeOnClick      = false;
	@Input() detectSpaceEnough = false;

	labelWidth = 'auto';
	showAbove  = false;

	itemHeight = 35; // Height of the items (that are scrolled)

	maxHeight: number; // Maximum height of the dropdown (dynamically calculated)

	maxWidth: number; // Maximum width of the dropdown (dynamically calculated)

	@ViewChild('searchInput') searchInput;

	@ViewChild('dropdownlist') dropdownlist: ElementRef;

	@ViewChild('dropdownListScrollViewport') dropdownListScrollViewport: CdkVirtualScrollViewport;

	@ContentChild('itemTemplate', {read: TemplateRef}) itemTemplate: TemplateRef<any>;

	@ContentChild('headerTemplate', {read: TemplateRef}) headerTemplate: TemplateRef<any>;

	@HostBinding('class.inverted-style')
	@Input() invertedStyling = false;

	/**
		* Additional classes that should be added to the items wrapper element (cssClass: dropdown-menu__list)
		*/
	@Input() additionalMenuListCssClasses: string;

	/**
		* The data source that contains the settings of the dropdown. E.g. groups, items and search settings.
		*/
	@Input() dataSource: any;

	/**
		* The identifier of the item that should be selected on initialization.
		*/
	@Input() selected: any;
	/**
		* Bar is small like regular input box
		*/
	@Input() isSmall                                                                = false;
	/**
		* Flag to set if the select is able to select multiple
		*/
	@Input() selectMultiple                                                         = false;
	/**
		* Event that is triggered when the selection is changed.
		*/
	@Output() selectionChanged: EventEmitter<SelectionChangedEventArgs>             = new EventEmitter<SelectionChangedEventArgs>();
	/**
		* Event that is triggered when the search input is changed
		*/
	@Output() searchInputChanged: EventEmitter<SelectionChangedWithSearchEventArgs> = new EventEmitter<SelectionChangedWithSearchEventArgs>();

	/**
		* Event that is trigged when the
		*/
	@Output() dropdownOpenStateChanged: EventEmitter<DropdownOpenStateEventArgs> = new EventEmitter<DropdownOpenStateEventArgs>();
	hasMultiSelectionEnabled                                                     = false;


	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(isDisabled: boolean) {
		this._disabled = isDisabled;
	}

	get showMenu(): boolean {
		return this._showMenu;
	}

	set showMenu(value: boolean) {
		if (value) {
			this.calculateMaxHeight(this.dataSource.values);
			this.dropdownInViewport();
			this.calculateWidth();
		}
		this.dropdownOpenStateChanged.emit(new DropdownOpenStateEventArgs(this, value));
		this._showMenu = value;
	}

	constructor(private changeRef: ChangeDetectorRef) {

	}


	ngOnChanges(changes: SimpleChanges) {
		if (changes.hasOwnProperty('dataSource')) {
			this.generateDataSourceItems(changes['dataSource'].currentValue);

			if (changes['dataSource'].currentValue.hasOwnProperty('values') &&
				!isNullOrUndefined(changes['dataSource'].currentValue.values)) {
				if (!changes['dataSource'].currentValue.values.find(x => x.data.length > 8)) {

					try {
						// Set search bar to false if theres less than 8 items
						changes['dataSource'].currentValue.search.hasSearchBar = false;
					} catch (e) {
						changes['dataSource'].currentValue.search = {
							hasSearchBar: false
						};
					}

					// Disabled combobox if there's one value
					if (changes['dataSource'].currentValue.values.length === 1
						&& changes['dataSource'].currentValue.values[0].data.length === 1) {
						this.setDisabledState(true);
					}
				}
			}
		}


		if (changes.hasOwnProperty('selected')) {
			const value = changes['selected'].currentValue;
			//   this.checkSelectedItemIsArray(value);
		}

		this.onSelectedItemChanged(this.selectedItem);
	}

	updateSelectedItems(value: Array<string | number>, withRemove = false) {
		for (const group of this.groups) {
			for (const tmpItem of group.data) {

				if (value.indexOf(tmpItem.identifier) > -1) {
					this.setSelected(tmpItem, withRemove);
				}

			}

		}
	}

	updateSelectedItem(itemIdentifier: string) {
		for (const group of this.groups) {
			for (const tmpItem of group.data) {
				// tslint:disable-next-line:triple-equals
				if (tmpItem.identifier == itemIdentifier) {
					this.setSelected(tmpItem);
					continue;
				}
				tmpItem.selected = false;
			}
		}
		// Check if a value is selected in the dropdown
		const allItems = this.groups.reduce((prev, curr) => {
			prev.push(...curr.data);
			return prev;
		}, []);

		// If no selected item is found pick the first one
		if (!allItems.find(x => x.selected === true))
			this.setSelected(allItems[0]);
	}

	generateDataSourceItems(dataSource: Array<any>) {
		const groups: Array<AdvancedDropdownGroup> = new Array<AdvancedDropdownGroup>();

		if (!isNullOrUndefined(dataSource)) {

			for (const i in dataSource.values) {
				if (dataSource.values.hasOwnProperty(i)) {
					const group = new AdvancedDropdownGroup(dataSource.values[i], this.selectMultiple);

					for (const dataItem of dataSource.values[i].data) {
						const item = new AdvancedDropdownItem(dataItem);
						group.data.push(item);
					}

					if (this.dataSource.selectedValue === null) {
						let msg = `Group "${group.label}" has no default value.`;
						if (group.data.length > 0) {
							if (!isNullOrUndefined(dataSource['default']))
								group.data.unshift(new AdvancedDropdownItem({
																																																					identifier: null,
																																																					label:      '-'
																																																				}));
							this.setSelected(group.data[0]);
							msg += `Set to first item: ${group.data[0].label}.`;
						}
						LoggerUtil.warn(msg);
					}

					groups.push(group);
				}
			}
		}

		this.groups         = groups;
		this.originalGroups = groups.map(g => g.clone());

		this.hasMultiSelectionEnabled = this.dataSource.selectedValue && isArray(this.dataSource.selectedValue)
																																		? this.dataSource.selectedValue.length > 1
																																		: false;

		this.checkSelectedItemIsArray(this.dataSource.selectedValue);

	}

	setSelected(item: AdvancedDropdownItem, withRemove = false) {
		item.selected = true;
		if (this.selectMultiple && this.hasMultiSelectionEnabled) {
			const index = this.selectedItem.findIndex(val => val.identifier.toString() === item.identifier.toString());
			if (index === -1)
				this.selectedItem.push(item);
			else if (withRemove) {
				this.selectedItem.splice(index, 1);
			}
		} else
			this.selectedItem = [item];

	}

	itemClicked(e) {
		console.log('itemClicked ' + e.item.identifier);

		const itemIdentifier = e.item.identifier;

		this.selectItem(itemIdentifier);
		if (this.closeOnClick) {
			this.hideMenu(new MouseEvent(''));
			this.changeRef.markForCheck();
		}
	}

	toggleMenuState(e) {
		if (!this.disabled) {
			this.showMenu = !this.showMenu;

			if (this.searchInput && this.searchInput.nativeElement) {
				// setTimeout is needed, although it seems odd with a 0ms delay
				setTimeout(handler => this.searchInput.nativeElement.focus(), 0);
			}

			if (!isNullOrUndefined(e)) {
				e.stopPropagation();
			}

			if (this.selected) {
				const groupIndex = this.groups.findIndex(x => {
					x.data.find(y => y.identifier === this.selected);
					return true;
				});


				const index = this.getFlattendItemList()
																						.findIndex(y => y.identifier === this.selected);


				// Wait for dropdown to be displayed
				setTimeout(() => {
					// Set highlighted element, this will automatically scroll to that item
					this.focussedElement = index;

					// when showing the menu, check if there is space below
					if (this.showMenu) {
						this.dropdownInViewport();
						this.calculateWidth(0);
					}

				}, 0);
			} else {
				// If we have item, highlight the first by default
				this.focussedElement = this.groups.length
																											? 0
																											: -1;
			}
		}
	}

	hideMenu(e) {
		if (this.searchInput && e.target === this.searchInput.nativeElement) {
			e.stopPropagation();
			return false;
		}
		if (this.showMenu) {
			this.showMenu = false;
		}
	}

	filterItems(query: string) {
		this.currentQuery = query;
		if (this.dataSource.search.useApiSearch) {

			if (query === '') {
				const copy           = this.newCopyOriginalGroups();
				this.groups          = copy;
				this.focussedElement = this.groups.length > 0
																											? 0
																											: -1;

				return;
			}

			const dropdownData = {
				identifier:     this.dataSource.identifier,
				label:          this.dataSource.label,
				selectMultiple: false,
				searchOptions:  {
					//levelName: this.dataSource.search.levelName,
					apiSearchParams: this.dataSource.search.apiSearchParams
				}
			};

			const eventArgs = new SelectionChangedWithSearchEventArgs(query,
																																																													dropdownData,
																																																													[
																																																														// new AdvancedDropdownItem({
																																																														// 																										identifier: e,
																																																														// 																										label:      this.label
																																																														// 																									})
																																																													], dropdownData.searchOptions.apiSearchParams, null, this.dataSource.dropdownType);

			this.searchInputChanged.emit(eventArgs);
		} else if (!this.dataSource.search || !this.dataSource.search.searchEndpoint) {
			const regex = new RegExp(query, 'i');
			const copy  = this.newCopyOriginalGroups();

			const found = copy.filter(group => {
				// @ts-ignore
				group.data = group.data.filter(item => {
					const text =
												isNullOrUndefined(item.label) && isString(item.label)
												? item.label
												: item.label.toString();
					return text.match(regex);
				});
				// @ts-ignore
				return group.data.length > 0;
			});
			this.groups = found.length > 0
																	? found
																	: [];


			this.focussedElement = this.groups.length > 0
																										? 0
																										: -1;
			LoggerUtil.debug(`Reset highlight focus to ${this.focussedElement}`);

			return;
		}
	}

	public writeValue(value: string) {
		if (!isNullOrUndefined(value)) {
			this.updateSelectedItem(value);
		}
	}

	public registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	public setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	onMultipleFilterKeydown(event: KeyboardEvent) {
		// Always repopuplate the new flattened list with all items from the filtered list
		const flattened = this.getFlattendItemList();

		const scrollHeight = this.dropdownListScrollViewport.elementRef.nativeElement.offsetHeight;

		const scrollPageSize = scrollHeight
																									? Math.floor(scrollHeight / this.itemHeight) - 1
																									: 8;

		// Handle keyboard navigation
		switch (event.key) {
			case 'Enter':
				if (this.focussedElement > -1) {
					const selectedItem = flattened[this.focussedElement];
					this.selectItem(selectedItem.identifier);
					this.toggleMenuState(event); // closes the menu upon pressing Enter
				}
				// do not submit the form
				event.preventDefault();
				event.cancelBubble = true;
				break;
			case 'ArrowUp':
				if (this.focussedElement > -1) {  // don't do anything if focus position < -1
					this.focussedElement--;
					// Prevent from parent viewpoint from scrolling as well
					event.preventDefault();
					event.cancelBubble = true;
				}
				break;
			case'ArrowDown':
				if (this.focussedElement < (flattened.length - 1)) {
					this.focussedElement++;
					// Prevent from parent viewpoint from scrolling as well
					event.preventDefault();
					event.cancelBubble = true;
				}
				break;
			case 'Home':
				this.focussedElement = 0;
				// Prevent from parent viewpoint from scrolling as well
				event.preventDefault();
				event.cancelBubble = true;
				break;
			case 'End':
				this.focussedElement = flattened.length - 1;
				// Prevent from parent viewpoint from scrolling as well
				event.preventDefault();
				event.cancelBubble = true;
				break;
			case 'PageUp':
				this.focussedElement = Math.max(0, this.focussedElement - scrollPageSize);
				// Prevent from parent viewpoint from scrolling as well
				event.preventDefault();
				event.cancelBubble = true;
				break;
			case 'PageDown':
				this.focussedElement = Math.min(flattened.length - 1, this.focussedElement + scrollPageSize);
				// Prevent from parent viewpoint from scrolling as well
				event.preventDefault();
				event.cancelBubble = true;
				break;
			case 'ArrowLeft':
			case 'ArrowRight': {
				console.log('Ignoring ' + event.key);
			}
				break;
			case 'Escape': {
				this.toggleMenuState(event);
				this.focussedElement = -1;
				console.log('Removing highlighting, due to key : ' + event.key);
			}
				break;
			default: {
				// This function is called high up in the DOM, don't do anything.
				// Let other functions handle the selection on key press. see filterInput().
				break;
			}
		}
	}

	/**
		* Sets the scroll position to include the item at index
		* @param index position in the flat list (i.e. without the grups)
		*/
	moveHighlightedIntoView(index: number) {
		LoggerUtil.debug(`Moving to index ${index}`);

		this.dropdownListScrollViewport.scrollToIndex(index);
	}

	onSelectContainerKeydown(event: any) {
		LoggerUtil.log('onSelectContainerKeydown : ' + event.target.value + ' (' + event.key + ')');
	}


	dropdownInViewport() {

		const bounding = (this.dropdownlist.nativeElement as HTMLElement).parentElement.getBoundingClientRect();

		// 304px is picked from .dropdown.dropdown-filter .dropdown-menu .cdk-virtual-scroll-viewport
		const height = this.maxHeight < 304
																	? this.maxHeight
																	: 304;

		if (bounding.top >= 0
			&& bounding.left >= 0
			&& bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
			&& (bounding.bottom + height + 50) <= (window.innerHeight || document.documentElement.clientHeight)
		) {
			LoggerUtil.debug('Element is in the viewport!');
			this.showAbove = false;
		} else {
			LoggerUtil.debug('Element is NOT in the viewport!');
			this.showAbove = true;
		}
	}

	trackSelected(item: AdvancedDropdownItem) {
		if (item === null)
			return null;

		return item.identifier;
	}

	trackByGroup(item: AdvancedDropdownGroup) {
		if (item === null)
			return null;

		return item.label;
	}

	deselectAll() {
		for (const group of this.groups) {
			for (const tmpItem of group.data) {
				tmpItem.selected = false;
			}

		}
		this.selectedItem = [];
		this.selectItem(null);
	}

	toggleMultiSelection($event: MouseEvent) {
		if (this.hasMultiSelectionEnabled)
			this.deselectAll();

		$event.cancelBubble = true;

		this.hasMultiSelectionEnabled = !this.hasMultiSelectionEnabled;
	}

	updateWithSearchData(values: {
		label: string,
		identifier: string
	}[]): void {

		if (this.currentQuery === '')
			return;

		this.groups[0].data = values.map(x => {
			return new AdvancedDropdownItem({
																																				'description': '',
																																				'isTeaser':    false,
																																				'label':       x.label,
																																				'identifier':  x.identifier,
																																				'params':      {}
																																			});
		});

		SafeMethods.detectChanges(this.changeRef);
	}

	private _disabled = false;
	private _showMenu = false;


	private _focussedElement = -1;

	private propagateChange(fn: any): any {
	}

	private onTouchedCallback(fn: any): any {
	}

	/**
		* Notifies underlying control value that selection has changed
		* Note: currently support only one selected item in ControlValueAssessor
		* @param selectedItem The item that is selected
		*/
	private onSelectedItemChanged(selectedItem: AdvancedDropdownItem[]) {
		this.propagateChange(
			!isNullOrUndefined(selectedItem)
			? this.selectMultiple
					? selectedItem.map(value => value.identifier)
					: selectedItem[0].identifier
			: ''
		);
	}

	private checkSelectedItemIsArray(value, withRemove = false) {
		if (isArray<string | number>(value))
			this.updateSelectedItems(value, withRemove);
		else
			this.updateSelectedItem(value);
	}

	private selectItem(itemIdentifier: any) {

		this.checkSelectedItemIsArray(this.selectMultiple
																																? [itemIdentifier]
																																: itemIdentifier, true);

		const newApiParams                       = {};
		// update apiParams with new selection by combining the id of the drop down and selection
		newApiParams[this.dataSource.identifier] = this.selectMultiple
																																													?
																																													this.selectedItem.map(value => value.identifier)
																																													: this.selectedItem[0].identifier;

		const dropdownData = {
			identifier:     this.dataSource.identifier,
			label:          this.dataSource.label,
			selectMultiple: this.selectMultiple
		};

		const eventArgs = new SelectionChangedEventArgs(
			dropdownData,
			this.selectedItem,
			newApiParams,
			this.dataSource.dropdownType
		);
		this.selectionChanged.emit(eventArgs);

		this.onSelectedItemChanged(this.selectedItem);
	}

	private newCopyOriginalGroups() {
		return this.originalGroups.map(g => g.clone());
	}

	private getFlattendItemList(): Array<AdvancedDropdownItem> {
		const flattened: Array<AdvancedDropdownItem> = [];

		// Always repopuplate the new flattened list with all items from the filtered list
		for (let i = 0; i < this.groups.length; i++) {
			for (let j = 0; j < this.groups[i].data.length; j++) {
				flattened.push(this.groups[i].data[j]);
			}
		}
		return flattened;
	}

	private handleOptionsWheel(e: any) {
		const div      = this.dropdownlist.nativeElement;
		const atTop    = div.scrollTop === 0;
		const atBottom = div.offsetHeight + div.scrollTop === div.scrollHeight;

		if (atTop && e.deltaY < 0) {
			e.preventDefault();
		} else if (atBottom && e.deltaY > 0) {
			e.preventDefault();
		}
	}

	private calculateMaxHeight(currentValue: Array<AdvancedDropdownGroup>) {

		if (currentValue == null)
			return null;

		const amount = currentValue.reduce((previousValue,
																																						currentValue1) =>
																																						(previousValue + currentValue1.data.length + (currentValue1.label
																																																																																				? 1
																																																																																				: 0)), 0);

		this.maxHeight = amount * this.itemHeight;
	}

	private calculateWidth(padding = 48) {
		const items  = (this.dropdownlist.nativeElement as HTMLElement)
			.querySelectorAll('cs-advanced-dropdown-item > a.dropdown-item');
		let maxWidth = 0;

		items.forEach((value: HTMLElement, key) => {
			const elementWidth = value.innerText.trim().length;
			maxWidth           = elementWidth > maxWidth
																								? elementWidth
																								: maxWidth;
			if (padding === 0)
				padding = parseInt(window.getComputedStyle(value, null)
																													.getPropertyValue('padding-right'), 0)
					+ parseInt(window.getComputedStyle(value, null)
																						.getPropertyValue('padding-left'), 0);
		});


		this.maxWidth = (maxWidth * 8) + padding;
	}
}
