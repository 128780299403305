import { FormatProviderService }                                                               from '@cs/common';
import { ChartItemClickEventArgs, CsChartGoogleProvider, CsChartLoaderSetup, CsChartProvider } from '@cs/components/cs-chart-loader';
import { FormatRegisteredItem, isNullOrUndefined }                                             from '@cs/core';
import { DashboardChartData }                                                                  from './dashboard-chart-data';
import { DashboardChartDataItem }                                                              from './dashboard-chart-data-item';

import DataObject = google.visualization.DataObject;
import DataObjectCell = google.visualization.DataObjectCell;
import DataObjectColumn = google.visualization.DataObjectColumn;
import DataObjectRow = google.visualization.DataObjectRow;


export class CsDashboardChartLoaderSetup implements CsChartLoaderSetup<any, DataObject> {

	constructor(private formatProvider: FormatProviderService) {
	}

	/**
	 * Set the chart provider to use for drawing charts
	 */
	createNewChartProviderInstance() {
		return new CsChartGoogleProvider();
	}

	async mapToDataTable(dataDescribed: DashboardChartData,
											 properties: Array<string>) {
		const dataObject: DataObject = {cols: [], rows: [], p: {}};

		for (const serie of dataDescribed.columns) {
			this.addColumn(serie.name, serie.label, serie.dataType, dataObject);
		}

		const dataList: any[] = <any>dataDescribed.rows;

		for (const dataItem of dataList) {
			this.addRow(dataItem, dataObject, dataDescribed);
		}
		return {dataTable: dataObject, legendItems: null};
	}

	addColumn(id: string, label: string, type: string, dataTable: DataObject, p = {}, pattern?) {
		const convertedType            = this.parseTypes(type);
		const header: DataObjectColumn = {id: id, label: label, type: convertedType, pattern: pattern, p: p};
		dataTable.cols.push(header);
	}

	addRow(dataItem: DashboardChartDataItem[], dataObject: google.visualization.DataObject, dataDescribed: DashboardChartData) {
		const row: DataObjectRow = {c: []};
		for (let i = 0; i < dataObject.cols.length; i++) {
			const dataAnno = dataDescribed.columns[i];
			const cellData = dataItem[i];
			let cellValue  = <any>cellData.v;
			// If the data-type contains Date / DateTime then convert it to a date
			if (!isNullOrUndefined(dataAnno.dataType) && dataAnno.dataType.toLowerCase().indexOf('date') > -1) {
				cellValue = new Date(cellData.v.toString());
			}
			const cell: DataObjectCell = {
				v: cellValue
			};

			if (dataAnno.format) {
				cell.f = this.formatProvider.format(cellValue, new FormatRegisteredItem(dataAnno.dataType || '', dataAnno.format));
			} else if (dataAnno.dataType) {
				cell.f = this.formatProvider.formatByDataType(cellValue, dataAnno.dataType);
			}
			row.c.push(cell);

		}

		dataObject.rows.push(row);
	}

	private parseTypes(type: string) {
		let result = 'string';
		switch (type.toLowerCase()) {
			case 'int':
			case 'int16':
			case 'int32':
			case 'int64':
			case 'decimal':
			case 'float':
			case 'number':
				result = 'number';
				break;
		}
		return result;
	}

	getMetaProperties(event: ChartItemClickEventArgs, chartProvider: CsChartProvider) {
	}
}
