import { OverlayRef }        from '@angular/cdk/overlay';
import { generateQuickGuid } from '@cs/core';

export class ToastRef {
  readonly guid = generateQuickGuid();

  constructor(private readonly overlay: OverlayRef) {
  }

  close() {
    this.overlay.dispose();
  }

  isVisible() {
    return this.overlay && this.overlay.overlayElement;
  }

  getPosition() {
    return this.overlay.overlayElement.getBoundingClientRect();
  }
}
