import { NgModule }                              from '@angular/core';
import { DefaultFormatRegistry, FormatRegistry } from '@cs/common';
import { CustomerRoutingModule }                 from '@cs/customer/customer-routing.module';
import { IJsmakerFormatRegistry } from '@cs/customer/ijsmaker-format-registry';

import { DefaultNavbarIconsFactory, SHELL_NAVBAR_ICONS_REPO } from '@cs/performance-manager';

export function CustomerNavbarIconsFactory() {
	const iconsLib = DefaultNavbarIconsFactory();
	iconsLib.set('reporting', 'icon icon-reporting');
	iconsLib.set('OPS/datamanagement', 'icon icon-order');
	iconsLib.set('configuration', 'icon icon-dashboard');
	return iconsLib;
}

/**
 * This bootstraps any angular customer specific code;
 */
@NgModule({
						declarations: [],
						imports:      [CustomerRoutingModule],
						providers:    [
							{provide: SHELL_NAVBAR_ICONS_REPO, useFactory: CustomerNavbarIconsFactory},
							{provide: FormatRegistry, useClass: IJsmakerFormatRegistry}
						],
						exports:      [CustomerRoutingModule]
					})
export class CustomerInitModule {}
