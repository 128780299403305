<div class="upload-form">
  <div class="logo"></div>
  <h1>{{ uploadFilesText$ | async }}</h1>
  <div class="upload-box" (click)="triggerFileInput()" (dragover)="handleDragOver($event)" (dragleave)="handleDragLeave($event)" (drop)="handleDrop($event)">
    <input #fileInput type="file" multiple (change)="handleFileSelect($event)" style="display: none;">
    <ng-container *ngIf="droppedFiles.length === 0; else fileDetails">
      <p>{{ uploadText$ | async }}</p>
      <small>.xls, .xlsx, .pdf<br>{{ maxFileSizeText$ | async }}: 10mb</small>
    </ng-container>
    <ng-template #fileDetails>
      <div *ngFor="let file of droppedFiles">
        <p class="file-details" [title]="file.name">
           {{ file.name }}
           <br>
           <span class="file-size">{{ file.size | fileSize:2 }}</span>
        </p>
      </div>
    </ng-template>
  </div>
  <div class="comment-box">
    <label for="comments">{{ commentsOptionalText$ | async }}</label>
    <input type="text" id="comments" [value]="commentText" (input)="handleCommentTextChange($event)" placeholder="{{ commentsPlaceholderText$ | async }}">
  </div>
  <button class="btn btn--primary btn--lg" (click)="buttonClicked()">
		{{ getButtonText() | async }}
  </button>
</div>
