import { ChangeDetectorRef, Component, ElementRef, forwardRef, Inject, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml }                                                      from '@angular/platform-browser';
import { ActivatedRoute, Router }                                                      from '@angular/router';
import { WaitingForResponse }                                                          from '@cs/common';
import { CsToastManagerService }                                                       from '@cs/components/toast-manager';
import { CsHttpRequestOptions }                                                        from '@cs/core';
import { LoginConfigService, LoginService }                                            from '@cs/performance-manager/login';
import { AuthenticationService }                                                       from '@cs/performance-manager/shared';
import { TranslateService }                                                            from '@ngx-translate/core';
import { take, tap }                                                                   from 'rxjs/operators';


declare var grecaptcha: any;

@Component({
			   selector:    'pmc-otp',
			   templateUrl: './otp.component.html'
		   })
export class OtpComponent implements OnInit, OnDestroy {

	private get alreadyInjectedDependency() {
		return document.getElementById('googleCaptcha') != null;
	}

	/**
	 * Flag for displaying the correct message
	 */
	userHasTOTP: boolean;

	/**
	 * Binding to the username field
	 */
	otpValue     = '';
	/**
	 * Binding to the amount of days authenticated
	 */
	auth2FADays  = false;
	/**
	 * Binding to the captcha field
	 */
	captcha      = '';
	/**
	 * The state of the form. Defaults to true because it's empty
	 */
	isErrorState = true;

	/**
	 * HTML of the Captcha
	 */
	captchaHtml: SafeHtml;

	/**
	 * Indicator for the loader
	 */
	isLoadingCaptcha = true;

	//#region Loaders

	/**
	 * Loader flag for the reset password button
	 */
	isWaitingForReset = false;

	/**
	 * use google captcha
	 */
	useGoogleCaptcha = true;

	//#endregion


	constructor(private sanitizer: DomSanitizer,
				@Inject(forwardRef(() => LoginService)) private loginService: LoginService,
				@Inject(forwardRef(() => LoginConfigService)) public loginConfig: LoginConfigService,
				private l8n: TranslateService,
				private toastService: CsToastManagerService,
				private authenticationService: AuthenticationService,
				private renderer: Renderer2,
				private changeRef: ChangeDetectorRef,
				private route: ActivatedRoute,
				private ngzone: NgZone,
				private router: Router,
				private element: ElementRef) {
		window['onloadRecaptchaCallback'] = () => {
			this.refreshCaptcha();
			// Set disabled upon int captcha callback
			const submit = document.getElementById('form-submit');
			submit['disabled'] = true;
		};

		window['submitForm'] = (token) => this.ngzone.run(() => {
			this.captcha	= token;
			this.verifyOtp();
		});

		this.l8n.get('LOGIN_WELCOME_MESSAGE')
			.subscribe(value => this.loginService.setTitleMessage(value));


	}

	ngOnInit() {
		this.loginConfig.initMultiFactor()
			.subscribe((result) => {
				this.isLoadingCaptcha     = false;
				const injectedRootUrlHtml = result.value.captchaHtml;
				this.userHasTOTP          = result.value.userHasTOTP;

				this.l8n.get(this.userHasTOTP
							 ? 'APP_OTP_HEADER_MESSAGE'
							 : 'EMAIL_OTP_HEADER_MESSAGE')
					.subscribe(value => this.loginService.setUnderTitleMessage(value));
				this.auth2FADaysAmountOfDays = result.value.cookieLifetimeOptions[0];
				this.useGoogleCaptcha        = result.value.requiresGoogleCaptcha;
				this.captchaHtml             = this.sanitizer.bypassSecurityTrustHtml(injectedRootUrlHtml);
				this.injectDependency();
				this.changeRef.detectChanges(); // TODO check if necessary
			});
	}

	ngOnDestroy(): void {
		this.captchaHtml = '';
		this.changeRef.detectChanges();
	}

	refreshCaptcha() {
		setTimeout(() => {
			const container = this.element.nativeElement.querySelector('.reset-password .g-recaptcha');
			try {
				grecaptcha.render(container, {
					'callback': (output) => {
						window['submitForm'](output);
					}
				});
				this.changeRef.detectChanges();
			} catch (e) {
				grecaptcha.reset();
			}
		}, 0);
	}

	/**
	 * Request a new email with OTP
	 */
	requestNewEmail() {
		const options: CsHttpRequestOptions = {
			errorResponseHandler: (error): boolean => {
				let message = error.statusText;
				try {
					// we might received more useful messages in the body
					const result = JSON.parse(error.message);
					if (result.hasOwnProperty('messages')) {
						message = (<string[]>result.messages).join(' ');
					}
				} catch (e) {
					if (error.error && error.error.hasOwnProperty('messages'))
						message = error.error.messages.join(' ');
					else if (error.error)
						message = error.error;
					else if (error.status === 500) {
						return false;
					}

				}
				this.toastService.show({
										   title:   error.status === 500
													? this.l8n.instant('ERROR_SOMETHING_WRONG')
													: this.l8n.instant('INFO'),
										   content: message,
										   type:    error.status === 500
													? 'error'
													: 'info'
									   });

				this.refreshCaptcha();
				return true;

			},
			headers:              null
		};
		this.loginConfig.requestNewOtp(options)
			.subscribe(() => {
				this.toastService.show({
										   content: this.l8n.instant('NEW_OTP_EMAIL_REQUESTED'),
										   type:    'info'
									   });
			});
	}

	/**
	 * Send the otp to the server, it contains the captcha
	 */
	verifyOtp() {

		const options: CsHttpRequestOptions = {
			errorResponseHandler: (error): boolean => {

				let message = error.statusText;
				try {
					// we might received more useful messages in the body
					const result = JSON.parse(error.message);
					if (result.hasOwnProperty('messages')) {
						message = (<string[]>result.messages).join(' ');
					}
				} catch (e) {
					if (error.error && error.error.hasOwnProperty('messages'))
						message = error.error.messages.join(' ');
					else if (error.error)
						message = error.error;
					else if (error.status === 500) {
						return false;
					}

				}
				this.toastService.show({
										   title:   error.status === 500
													? this.l8n.instant('ERROR_SOMETHING_WRONG')
													: this.l8n.instant('INFO'),
										   content: message,
										   type:    error.status === 500
													? 'error'
													: 'info'
									   });

				this.refreshCaptcha();
				return true;


			},
			headers:              null
		};

		this.loginConfig.verifyOtp(this.otpValue, this.captcha, this.auth2FADays
																? this.auth2FADaysAmountOfDays
																: '0', options)
			.pipe(tap(WaitingForResponse.new(isLoading => this.isWaitingForReset = isLoading)))
			.subscribe((result) => {
				this.router.navigate(['/'], {relativeTo: this.route.root});
			});
	}

	getErrorMessage() {
		if (this.isLoadingCaptcha) {
			return this.l8n.instant('CAPTCHA_STILL_LOADING');
		} else if (this.otpValue.length === 0) {
			return this.l8n.instant('NO_CODE_PROVIDED_MAIL');
		}
	}

	checkErrorState() {
		this.isErrorState = this.captcha.length === 0 || this.otpValue.length === 0 || this.isLoadingCaptcha;
	}


	goBackOfLogout() {
		this.authenticationService.logOut();
	}

	private auth2FADaysAmountOfDays: string;

	private injectDependency(): void {
		if (this.alreadyInjectedDependency) {
			this.refreshCaptcha();
			return;
		}

		const script   = document.createElement('script');
		script.id      = 'googleCaptcha';
		script.src     = 'https://www.google.com/recaptcha/api.js?onload=onloadRecaptchaCallback';
		script.type    = 'text/javascript';
		script.async   = true;
		script.defer   = true;
		script.charset = 'utf-8';
		this.renderer.appendChild(document.head, script);
	}
}
