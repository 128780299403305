<ng-template [ngIf]="headerTemplate">
	<ng-container *ngTemplateOutlet="headerTemplate; context: headerTemplateContext"></ng-container>
</ng-template>

<ng-template [ngIf]="!headerTemplate && !group.clickableGroupLabel">
		<a *ngIf="group.label" class="dropdown-item dropdown-header">{{group.label}}</a>
</ng-template>

<ng-template [ngIf]="itemTemplate">
	<ng-container *ngFor="let item of group.data">
		<ng-container *ngTemplateOutlet="itemTemplate; context: getItemTemplateContext(item)"></ng-container>
	</ng-container>
</ng-template>

<ng-template [ngIf]="!itemTemplate">
	<a *ngFor="let item of group.data" (click)="onItemClicked(item)"
	   [ngClass]="{'active': item.selected && !item.highlighted, 'highlight': item.highlighted, 'disabled':item.disabled, 'mb-2': item.isLast, 'dropdown-header dropdown-header__clickable' : item.isGroupLabel}"
	   (mouseover)="detectTruncatedField($event, item)"
	   [matTooltipDisabled]="!(item.description || item.isTruncated)"
	   matTooltipPosition="right"
	   matTooltip="{{getDescription(item)}}"
	   class="dropdown-item">{{item.label}} <i *ngIf="item.isTeaser" class="developer-logo-icon developer-logo-icon--light ml-2 "></i>
	</a>
</ng-template>

<div *ngIf="addDivider" class="dropdown-divider"></div>
